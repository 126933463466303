<template>
  <div class="flex flex-col min-h-screen">
    <IndexNavbar />
    <!--Conteúdo -->
    <div class="container mx-auto mt-24 px-4 pb-">
      <slot />
      <CookieConsentCard v-if="!confirmedConsent" />
    </div>
  </div>    
  <Footer />
</template>

<script setup>
import { useCookiesConsentStore } from "~~/stores/cookies-consent";

const store = useCookiesConsentStore();
const confirmedConsent = ref(false);

onMounted(() => {
  confirmedConsent.value = store.getConsent();
})
</script>

<style scoped></style>
