<template>
  <div>
    <a class="bg-pink-jpd-400 p-6 rounded text-white px-3 py-2 flex items-center text-xs uppercase font-bold" href="#"
      ref="btnDropdownRef" @click.self.prevent="toggleDropdown">
      Acesso
    </a>
    <div ref="popoverDropdownRef"
      class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48" :class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }">
      <template v-if="!auth.token">
        <NuxtLink to="/user/login"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          @click="setNavbarOpen">
          Entrar
        </NuxtLink>
        <NuxtLink to="/user/register"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          @click="setNavbarOpen">
          Registrar
        </NuxtLink>
      </template>
      <template v-else="auth.token">
        <NuxtLink to="/user/management/"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          @click="setNavbarOpen">
          Perfil
        </NuxtLink>
        <NuxtLink to="#" @click.self.prevent="logout"
          class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          @click="setNavbarOpen">
          Sair
        </NuxtLink>
      </template>
    </div>
  </div>
</template>
<script setup>
import { createPopper } from "@popperjs/core";
import { useAuthStore } from "~~/stores/auth";

const props = defineProps({
  navbarOpen: {
    type: Object,
    required: true
  }
})

const auth = useAuthStore();
const dropdownPopoverShow = ref(false);
const btnDropdownRef = ref();
const popoverDropdownRef = ref();

//Força status do menu para ser fechado ao clicar nos links
function setNavbarOpen() {
  props.navbarOpen.visible = !props.navbarOpen.visible;
  dropdownPopoverShow.value = false;
}

function toggleDropdown() {
  //event.preventDefault();
  if (dropdownPopoverShow.value) {
    dropdownPopoverShow.value = false;
  } else {
    dropdownPopoverShow.value = true;
    createPopper(btnDropdownRef.value, popoverDropdownRef.value, {
      placement: "bottom-start",
    });
  };
}

async function logout() {
  //Limpa cookies auth
  auth.clear()
  return navigateTo('/user/login')
}

</script>